import Filter from './filter';
import PaginationAction from './pagination-action';
import PaginationControls from './pagination-controls';
import { Container, Table, ConfigurationProvider } from './table';
import PaginationBar from './pagination-bar';
import { TableCell } from './table-cell';

export class Datatable {
  static Container = Container;
  static Table = Table;
  static Cell = TableCell;
  static Filter = Filter;
  static PaginationAction = PaginationAction;
  static PaginationControls = PaginationControls;
  static ConfigurationProvider = ConfigurationProvider;
}

export { PaginationBar };

export type { ColumnDefinition, DatatableProps, RowModel } from './types';
