import * as React from 'react';

export const QuoteIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M16.6667 18.3333H3.33333C3.11232 18.3333 2.90036 18.2455 2.74408 18.0892C2.5878 17.933 2.5 17.721 2.5 17.5V2.49999C2.5 2.27898 2.5878 2.06701 2.74408 1.91073C2.90036 1.75445 3.11232 1.66666 3.33333 1.66666H16.6667C16.8877 1.66666 17.0996 1.75445 17.2559 1.91073C17.4122 2.06701 17.5 2.27898 17.5 2.49999V17.5C17.5 17.721 17.4122 17.933 17.2559 18.0892C17.0996 18.2455 16.8877 18.3333 16.6667 18.3333ZM15.8333 16.6667V3.33332H4.16667V16.6667H15.8333ZM6.66667 5.83332H13.3333V7.49999H6.66667V5.83332ZM6.66667 9.16666H13.3333V10.8333H6.66667V9.16666ZM6.66667 12.5H13.3333V14.1667H6.66667V12.5Z'
        fill='currentColor'
        // fill='#CAD6E2'
      />
    </svg>
  ),
);
