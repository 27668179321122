const isProduction: boolean = import.meta.env.NODE_ENV === 'production';

export const log = (error: Error, options?: unknown) => {
  if (isProduction) {
    // Report log
    // TODO
  } else {
    // eslint-disable-next-line no-console
    console.error({ error, options });
  }
};
