import { useEffect, useState } from 'react';
import { LocalStorageStore } from '../helpers';
import { StoreApi } from '@wyz/types';

type Setter<State> = (arg: State | ((previous: State) => State)) => void;
function usePersistedState<State = string>(
  key: string,
  defaultState: State,
  store: StoreApi<State> = LocalStorageStore,
): [State, Setter<State>] {
  const [state, setState] = useState<State>(store.get(key) || defaultState);

  useEffect(() => {
    store.set(key, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return [state, setState];
}

export default usePersistedState;
