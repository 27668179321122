export type ISuccessApiResponse<
  TData = unknown,
  TMeta = unknown,
  TMessage extends string = string,
> = {
  message?: TMessage;
  data: TData;
  meta: TMeta;
  status: 'SUCCESS';
};
export type IErrorApiResponse<
  TError = unknown,
  TMessage extends string = string,
  TMeta = unknown,
> = {
  message: TMessage;
  errors: Array<TError>;
  status: 'ERROR';
  meta: TMeta;
};

export type IValidationError<ErrorMessage extends string = string> = {
  propertyPath: string;
  value: unknown;
  errors: Array<ErrorMessage>;
};
export type ValidationErrorResponse<ErrorMessage extends string = string> =
  IErrorApiResponse<IValidationError<ErrorMessage>>;

// Pagination
export type SortOrder = 'asc' | 'desc' | undefined;
export interface ISorter {
  [key: string]: SortOrder;
}

export type FilterOperation = 'and' | 'or';
export type FieldOperator =
  | 'eq'
  | 'range'
  | 'neq'
  | 'contains'
  | 'in'
  | 'elemMatch'
  | 'nelemMatch';

export type IFilterCondition = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  column?: string;
  operator?: FieldOperator | '';
};
export interface IFilter {
  operator: FilterOperation;
  query?: string;
  conditions: IFilterCondition[];
}

export type IMultiFieldSearchQuery = {
  searchableFields: Array<string>;
  searchValue: string;
  displayedField: string;
};

export const ITEMS_PER_PAGE = [10, 20, 50, 100] as const;
export type ItemsPerPage = (typeof ITEMS_PER_PAGE)[number];

export type IPaginationQuery<TCriteria = IFilter> = {
  itemsPerPage: ItemsPerPage;
  page: number;
  criteria: TCriteria;
  sort: {
    [field: string]: SortOrder;
  };
};

export interface IPaginationMeta {
  currentPage: number;
  from: number;
  to?: number;
  hasMore: boolean;
  perPage: number;
  total: number;
}

export type IPaginationResponse<
  TData = unknown,
  TAdditionalMeta = unknown,
> = ISuccessApiResponse<Array<TData>, IPaginationMeta & TAdditionalMeta>;

export type ICancellationOptions = { signal: AbortSignal };
