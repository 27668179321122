import * as React from 'react';

export const ArrowLeftRightLineIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M11.375 9.04134L15.5 13.1663L11.375 17.2913L10.1967 16.113L12.31 13.9988L1.33333 13.9997V12.333H12.31L10.1967 10.2197L11.375 9.04134ZM4.625 0.708008L5.80333 1.88634L3.69 3.99967H14.6667V5.66634H3.69L5.80333 7.77967L4.625 8.95801L0.5 4.83301L4.625 0.708008V0.708008Z'
        fill='#B5C0CE'
      />
    </svg>
  ),
);
