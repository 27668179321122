import * as React from 'react';

export const ReloadIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M14.5088 2.31978C16.2276 2.76521 17.7927 3.65909 19.0468 4.90454L19.13 2L21.129 2.05181L20.9383 8.70681L13.9405 8.52546L13.995 6.62441L17.3173 6.71051C16.3714 5.75967 15.1855 5.07772 13.8817 4.73983C12.4263 4.36268 10.8912 4.43162 9.47551 4.93771C8.05982 5.4438 6.8289 6.36369 5.94248 7.578C5.05605 8.79232 4.55504 10.245 4.50444 11.7476L2.00586 11.6635C2.07332 9.66002 2.74134 7.72309 3.92324 6.104C5.10514 4.48491 6.74636 3.2584 8.63394 2.58361C10.5215 1.90882 12.5684 1.8169 14.5088 2.31978Z'
        fill='currentColor'
      />
      <path
        d='M6.69997 17.3064L10.0063 17.4216L10.0795 15.5211L3.08382 15.2774L2.82756 21.9304L4.82591 22L4.93837 19.0803C6.19499 20.3337 7.76579 21.233 9.49156 21.6802C11.432 22.1831 13.4789 22.0912 15.3664 21.4164C17.254 20.7416 18.8953 19.5151 20.0772 17.896C21.259 16.2769 21.9271 14.34 21.9945 12.3365L19.4959 12.2524C19.4453 13.755 18.9443 15.2077 18.0579 16.422C17.1715 17.6363 15.9406 18.5562 14.5249 19.0623C13.1092 19.5684 11.5741 19.6373 10.1187 19.2602C8.82262 18.9243 7.64307 18.2484 6.69997 17.3064Z'
        fill='currentColor'
      />
    </svg>
  ),
);
