import { RecordValues } from '@wyz/types';
import { Locale, intlFormatDistance, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { fr } from 'date-fns/locale';
export const DATE_FORMAT = {
  FULL_DATE_TIME: 'dd/MM/yyyy HH:mm',
  DAY_MONTH: 'dd/MM',
  SHORT_DATE: 'dd/MM/yy',
  RELATIVE_TIME: 'fromNow',
  TIME_ONLY: 'HH:mm',
  STANDARD_SHORT_DATE: 'yyyy-MM-dd',
} as const;
type Format = RecordValues<typeof DATE_FORMAT>;
type Options = {
  format?: Format;
  locale?: 'fr-FR' | 'en-US';
  timezone?: string;
};
const DEFAULT_TIMEZONE = 'Europe/Paris';

const LOCALE_LOOKUP_KEY = {
  'fr-FR': 'fr',
  'en-US': 'en',
} as const;

const LOCALE_LOOKUP_MODULE = {
  fr: fr,
  en: undefined,
} as Record<string, Locale | undefined>;

/**
 * Format a timestamp as a string
 * @param {Date} date
 *
 * @return {string}
 */
export const formatTimestamp = (
  date: Date,
  {
    format = DATE_FORMAT.FULL_DATE_TIME,
    locale = 'fr-FR',
    timezone = DEFAULT_TIMEZONE,
  }: Options = {
    format: DATE_FORMAT.FULL_DATE_TIME,
  },
): string => {
  return format === 'fromNow'
    ? intlFormatDistance(date, new Date(), {
        locale: LOCALE_LOOKUP_KEY[locale],
      })
    : formatInTimeZone(date, timezone, format, {
        locale: LOCALE_LOOKUP_MODULE[LOCALE_LOOKUP_KEY[locale]],
      });
};

/**
 * updateDateToCurrentTime
 */
export const nowise = (date: Date) => {
  const currentDate = new Date();
  date.setHours(currentDate.getHours());
  date.setMinutes(currentDate.getMinutes());
  date.setSeconds(currentDate.getSeconds());
  return date;
};

export type DateFormatOptions = NonNullable<
  Parameters<typeof formatTimestamp>[1]
>;

export const isValidDate = (date: string) => {
  const parsedDate = new Date(date);
  return isValid(parsedDate);
};
