import { DependencyList, useEffect } from 'react';

import useTimeoutFn from './use-timeout-fn';

export type UseDebounceReturn = [() => boolean | undefined, () => void];

export default function useDebounce(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  function_: Function,
  deps: DependencyList = [],
  ms = 0,
): UseDebounceReturn {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [isReady, cancel, reset] = useTimeoutFn(function_, ms);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reset, deps);

  return [isReady, cancel];
}
