/* eslint-disable @wyz/module/no-relative-import */
import './assets/scss/theme.scss';
import { createRoot } from 'react-dom/client';

import AppsRouter from './apps-router';

const container = document.getElementById('root');
if (!container) {
  throw new Error('unable to find root element');
}
const root = createRoot(container);

root.render(<AppsRouter />);
