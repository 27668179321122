import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Badge, Nav, Navbar } from '@wyz/ui';
import { useConfig, useTheme } from '~/common/context';
import { Logo, TopNavbarPortal } from '~/common/components';
// eslint-disable-next-line @wyz/module/no-relative-import
import TopNavRightSideNavItem from './top-nav-right-side-nav-item';

const NavbarTop = () => {
  const {
    showBurgerMenu,
    navbarPosition,
    navbarCollapsed,
    topNavbarBreakpoint,
    setConfig,
  } = useTheme();

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    if (navbarPosition === 'top' || navbarPosition === 'double-top')
      setConfig('navbarCollapsed', !navbarCollapsed);
    if (navbarPosition === 'vertical' || navbarPosition === 'combo')
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={clsx(
        'navbar-glass fs-xxs navbar-top fixed-navbar sticky-kit',
        {
          'navbar-glass-shadow': showDropShadow,
        },
      )}
      expand={
        navbarPosition === 'top' ||
        navbarPosition === 'combo' ||
        navbarPosition === 'double-top'
          ? topNavbarBreakpoint
          : true
      }
    >
      <NavbarTopElements
        navbarPosition={navbarPosition}
        handleBurgerMenu={handleBurgerMenu}
      />
    </Navbar>
  );
};

type NavbarTopElementsProps = {
  navbarPosition: string;
  handleBurgerMenu: () => void;
};
const NavbarTopElements = ({
  navbarPosition,
  handleBurgerMenu,
}: NavbarTopElementsProps) => {
  const burgerMenuRef = useRef(null);
  const { navbarBreakPoint, topNavbarBreakpoint } = useTheme();
  const { environment } = useConfig();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={clsx('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none':
            navbarPosition === 'top' || navbarPosition === 'double-top',
          [`d-${String(navbarBreakPoint)}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo',
        })}
        as='div'
      >
        <button
          className='navbar-toggler-humburger-icon btn btn-link d-flex flex-center'
          onClick={handleBurgerMenu}
          id='burgerMenu'
        >
          <span className='navbar-toggle-icon'>
            <span className='toggle-line' />
          </span>
        </button>
      </Navbar.Toggle>
      <Logo at='navbar-top' width={50} id='topLogo' />
      {environment !== 'prod' && (
        <Badge pill bg='danger' className={'p-2 m-2 fs-xs fw-semi-bold'}>
          {environment}
        </Badge>
      )}
      <TopNavbarPortal />
      <Nav
        navbar
        className={`align-items-center d-none d-${
          topNavbarBreakpoint as string
        }-block`}
        as='ul'
      >
        <Nav.Item as='li'>
          {/* <SearchBox autoCompleteItem={autoCompleteInitialItem} /> */}
        </Nav.Item>
      </Nav>

      <TopNavRightSideNavItem />
    </>
  );
};

export default NavbarTop;
