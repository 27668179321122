import { Flex, Spinner as BSpinner, Icon } from '../index';
import { ReactNode } from 'react';
export function Spinner() {
  return <BSpinner animation='grow' />;
}

export function FullPageSpinner(props: { children?: ReactNode }) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Flex alignItems='center'>
        <Spinner />
        {props.children}
      </Flex>
    </div>
  );
}

export function CenteredSpinner() {
  return (
    <Flex alignItems='center' justifyContent='center'>
      <Icon icon='spinner' size='md' />
    </Flex>
  );
}

export function AbsoluteSpinner(props: { children?: ReactNode }) {
  return (
    <div style={{ position: 'fixed', inset: 0, zIndex: 9999 }}>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Flex alignItems='center' className='space-x-2'>
          <Flex>
            <Spinner />
          </Flex>
          <div>{props.children}</div>
        </Flex>
      </div>
    </div>
  );
}
