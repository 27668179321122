import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface LogoProps {
  at?: 'navbar-vertical' | 'navbar-top' | 'auth';
  width?: number;
  className?: string;
  textClass?: string;
  id?: string;
}

const Logo = ({
  at = 'auth',
  width = 58,
  className,
  textClass,
  ...rest
}: LogoProps) => {
  return (
    <Link
      to='/'
      className={clsx(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' },
      )}
      {...rest}
    >
      <div
        className={clsx(
          'd-flex',
          {
            'align-items-center':
              at === 'navbar-vertical' || at === 'navbar-top',
            'flex-center fw-bolder fs-4xl mb-4': at === 'auth',
          },
          className,
        )}
      >
        <SvgImage />
      </div>
    </Link>
  );
};

export default Logo;

function SvgImage() {
  return (
    <svg
      width='59'
      height='30'
      viewBox='0 0 59 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5076 3.2441C18.973 5.00558 15.8201 15.3519 15.7452 15.3519C15.6635 15.3519 13.6886 4.1749 13.6886 4.1749C13.6001 3.77069 13.3107 3.36277 13.0826 3.19589C12.7387 2.92147 12.4322 2.8473 12.0543 2.80651L11.2814 2.78426C10.849 2.78426 10.4608 2.81764 10.1612 2.87326C9.58237 3.00306 8.98992 3.44435 8.82309 4.04511C8.82309 4.04511 7.06277 15.4409 7.00829 15.4409C6.94019 15.4409 2.96671 2.98451 2.96671 2.98451C2.92585 2.8436 2.90542 2.78426 2.78285 2.78426H0.147475C0.0555435 2.79168 -0.0193637 2.89922 0.00447043 3.02531L4.25034 16.902C4.43421 17.5139 4.65212 18.022 5.31947 18.3557C5.72465 18.5411 6.44308 18.6153 6.947 18.6042C7.502 18.593 8.04678 18.5523 8.56772 18.3817C9.09207 18.2111 9.41894 17.8885 9.58237 17.1653L11.295 7.37893C11.3359 7.4086 12.6638 14.0651 12.9872 17.3174C13.0724 17.9515 13.6274 18.2593 14.1245 18.4447C14.6488 18.6413 15.2072 18.645 15.7452 18.645C16.0414 18.645 16.3308 18.6264 16.6066 18.5893C16.8824 18.5523 17.1923 18.5078 17.4476 18.4002C17.9141 18.2037 18.1388 17.7401 18.3193 17.1208L21.3019 7.13047L19.5042 3.2441H19.5076Z'
        fill='#007294'
      />
      <path
        d='M33.7501 2.87326C33.7297 2.80651 33.6718 2.77684 33.5799 2.77684H30.8764C30.73 2.77684 30.6177 2.87697 30.5359 3.08093L28.1457 8.89566L27.4171 10.4903L24.3289 3.03643C24.2608 2.86214 24.1484 2.77314 23.9884 2.77314H21.227C21.227 2.77314 21.227 2.77314 21.2236 2.77314H20.9785L21.1181 3.07722C21.1181 3.07722 21.1215 3.09206 21.1283 3.09947L25.987 13.6312L23.9305 18.148C23.9033 18.2222 23.8999 18.289 23.9203 18.3557C23.9407 18.4225 23.9986 18.4521 24.0905 18.4521H26.794C26.9404 18.4521 27.0528 18.352 27.1345 18.148L28.8335 13.9057L33.7433 3.07722C33.7706 3.00306 33.774 2.9363 33.7536 2.86955L33.7501 2.87326Z'
        fill='#007294'
      />
      <path
        d='M43.8694 2.77314H41.4452C41.4452 2.77314 41.4349 2.77314 41.4315 2.77314H35.4832L34.0804 5.80658H40.6722C40.7369 5.80658 40.7846 5.87333 40.7063 5.95863C40.662 6.00684 33.3143 12.9563 33.3143 12.9563C32.6231 13.6461 32.252 14.2654 32.0613 14.6473C31.8707 15.0293 31.6255 15.6375 31.6255 16.4311C31.6255 17.2247 31.9047 17.7216 32.1567 18.0331C32.5278 18.4892 33.2428 18.5968 34.1485 18.6116H44.4414C44.5776 18.6116 44.6457 18.5374 44.6457 18.3891V15.8044C44.6457 15.656 44.5776 15.5818 44.4414 15.5818H35.5003C35.3947 15.5818 35.3368 15.5559 35.3028 15.504C35.2687 15.4521 35.2347 15.3037 35.33 15.1999L43.0182 7.96115C43.8558 7.17497 44.6015 6.47038 44.7343 6.34059C44.9215 6.15888 44.9045 5.75096 44.9045 5.75096V3.51852C44.9045 2.69526 43.8626 2.76201 43.8626 2.76201L43.8694 2.77314Z'
        fill='#007294'
      />
      <path
        d='M56.0282 13.0157C55.9533 13.0157 55.8784 12.9786 55.8273 12.9118C54.6288 11.2838 52.4599 8.64349 51.7449 8.49515C51.0503 8.34682 47.608 10.3753 45.6127 11.6844C45.497 11.7585 45.3471 11.7251 45.2722 11.6065C45.1939 11.4878 45.2178 11.3209 45.3233 11.2319C47.322 9.50383 50.1855 6.77447 50.2978 6.1218C50.417 5.43204 48.9393 2.25396 47.9791 0.414612C47.9144 0.288528 47.9485 0.129068 48.0574 0.0474836C48.1664 -0.0341005 48.3162 -0.00814194 48.4013 0.106818C49.593 1.74963 51.7483 4.40853 52.4701 4.55315C53.1886 4.7052 56.6207 2.65447 58.6125 1.33058C58.7249 1.25641 58.8781 1.28608 58.9564 1.41216C59.0313 1.53454 59.0075 1.70142 58.9019 1.79042C57.0565 3.32939 54.0806 6.07359 53.941 6.88201C53.8014 7.69044 55.2825 10.8166 56.2495 12.6003C56.3176 12.7264 56.2836 12.8859 56.1746 12.9712C56.1304 13.0045 56.0793 13.0194 56.0282 13.0194V13.0157Z'
        fill='#00A9CB'
      />
      <path
        d='M26.239 29.3807C25.7385 29.7923 25.1052 30 24.3459 30C23.3449 30 22.5754 29.6403 22.0374 28.9172C21.5641 28.2682 21.3258 27.4375 21.3258 26.4251C21.3258 25.4127 21.5675 24.5524 22.051 23.922C22.589 23.2026 23.3653 22.8391 24.3868 22.8391C25.65 22.8391 26.4774 23.3361 26.8689 24.3299L26.508 24.5672C26.3922 24.2335 26.1777 23.9405 25.8645 23.6846C25.4627 23.3694 24.969 23.21 24.3868 23.21C23.4913 23.21 22.8171 23.54 22.3609 24.2001C21.9591 24.7712 21.7582 25.5129 21.7582 26.4288C21.7582 27.3448 21.9591 28.0939 22.3609 28.6576C22.8273 29.3177 23.5015 29.6477 24.3868 29.6477C24.9315 29.6477 25.4082 29.4994 25.8168 29.2064C26.2254 28.9134 26.4944 28.5018 26.6272 27.9752L26.9881 28.1866C26.8349 28.6798 26.5863 29.0803 26.2356 29.3844L26.239 29.3807Z'
        fill='#18233E'
      />
      <path
        d='M29.0174 23.9257C29.5554 23.2063 30.3317 22.8428 31.3531 22.8428C32.3746 22.8428 33.1577 23.2026 33.7025 23.9257C34.186 24.5598 34.4277 25.3942 34.4277 26.4288C34.4277 27.4635 34.186 28.3053 33.7025 28.9468C33.1475 29.6514 32.3644 30 31.3531 30C30.3419 30 29.5554 29.6477 29.0174 28.9468C28.5339 28.3053 28.2921 27.4672 28.2921 26.4288C28.2921 25.3905 28.5339 24.5561 29.0174 23.9257ZM29.3272 28.6576C29.7937 29.3177 30.4679 29.6477 31.3531 29.6477C32.2384 29.6477 32.9194 29.3177 33.3926 28.6576C33.7944 28.0939 33.9953 27.3522 33.9953 26.4288C33.9953 25.5055 33.7944 24.7712 33.3926 24.2001C32.9194 23.54 32.2384 23.21 31.3531 23.21C30.4679 23.21 29.7835 23.54 29.3272 24.2001C28.9255 24.7712 28.7246 25.5129 28.7246 26.4288C28.7246 27.3448 28.9255 28.0939 29.3272 28.6576Z'
        fill='#18233E'
      />
      <path
        d='M35.9429 22.9875H36.3719V24.1222C36.7294 23.3768 37.4989 23.006 38.6804 23.0134L38.4114 23.3843C38.0812 23.3843 37.8428 23.4102 37.6998 23.4621C37.3968 23.5511 37.0835 23.7736 36.7601 24.1334C36.5013 24.4337 36.3719 24.8157 36.3719 25.2792V29.8257H35.9429V22.9838V22.9875Z'
        fill='#18233E'
      />
      <path
        d='M42.0751 30C41.0808 30 40.325 29.6403 39.8074 28.9172C39.3512 28.2756 39.123 27.4449 39.123 26.4251C39.123 25.4053 39.3512 24.5784 39.8074 23.9479C40.325 23.21 41.0842 22.8391 42.0751 22.8391C43.0659 22.8391 43.7945 23.1692 44.3018 23.8293C44.7683 24.4189 44.9998 25.2088 44.9998 26.2026V26.4511H39.5657V26.5549C39.5657 27.4004 39.7666 28.1013 40.1683 28.665C40.6246 29.3139 41.2613 29.6403 42.0751 29.6403C43.2021 29.6403 43.9682 29.1211 44.3699 28.0865L44.7172 28.2719C44.5368 28.7392 44.2576 29.1211 43.8728 29.4178C43.3723 29.8035 42.7731 29.9963 42.0751 29.9963V30ZM44.5436 26.0728C44.5436 25.2718 44.3495 24.6154 43.9682 24.0963C43.5392 23.5066 42.9331 23.2137 42.1568 23.2137C41.3805 23.2137 40.7199 23.4992 40.2637 24.0703C39.8619 24.5895 39.6338 25.257 39.5793 26.0728H44.5436Z'
        fill='#18233E'
      />
    </svg>
  );
}
