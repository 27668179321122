import { isObject } from 'lodash-es';
import { IStringifyOptions, stringify, parse as qsParse } from 'qs';
import { isValidDate } from './date';

export const serialize = (
  object: unknown,
  options?: IStringifyOptions,
): string => {
  if (isObject(object)) return stringify(object, options);
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serializeFormData = (formData: Record<string, any>) =>
  serialize(formData, { arrayFormat: 'indices' });

export const parseFormData = <T extends { [key: string]: unknown }>(
  string: string,
): T =>
  qsParse(string, {
    decoder(value, defaultDecode, _, type) {
      if (type === 'key') {
        return defaultDecode(value);
      }

      if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const keywords: Record<string, any> = {
        true: true,
        false: false,
        null: null,
        undefined: undefined,
      };
      if (value in keywords) {
        return keywords[value];
      }
      const decodedValue = decodeURIComponent(value);
      if (isValidDate(decodedValue)) {
        return new Date(decodedValue);
      }
      return decodedValue;
    },
  }) as T;
