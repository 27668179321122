import * as React from 'react';

export const EyeOffIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M14.8999 16.5804C13.4343 17.51 11.7339 18.0023 9.99826 17.9996C5.50492 17.9996 1.76659 14.7662 0.982422 10.4996C1.34078 8.5585 2.31711 6.78531 3.76576 5.44457L1.15826 2.83957L2.33742 1.6604L18.8366 18.1604L17.6574 19.3387L14.8991 16.5804H14.8999ZM4.94409 6.62457C3.81159 7.65423 3.02269 9.00689 2.68409 10.4996C2.94433 11.6383 3.46681 12.7005 4.20992 13.6017C4.95304 14.5029 5.89628 15.2182 6.96455 15.6907C8.03282 16.1631 9.19663 16.3796 10.3633 16.323C11.53 16.2664 12.6674 15.9382 13.6849 15.3646L11.9949 13.6746C11.2755 14.1277 10.4234 14.323 9.57845 14.2283C8.73349 14.1335 7.94579 13.7545 7.34456 13.1533C6.74334 12.552 6.36428 11.7643 6.26956 10.9194C6.17484 10.0744 6.37008 9.22233 6.82326 8.5029L4.94409 6.62457ZM10.7599 12.4396L8.05826 9.7379C7.90998 10.1153 7.87508 10.5279 7.95784 10.9249C8.04061 11.3218 8.23744 11.6861 8.52419 11.9728C8.81094 12.2596 9.17514 12.4564 9.57214 12.5391C9.96913 12.6219 10.3816 12.587 10.7591 12.4387L10.7599 12.4396ZM17.3374 14.3262L16.1449 13.1346C16.702 12.3407 17.0986 11.4456 17.3124 10.4996C17.086 9.50767 16.6601 8.57219 16.0609 7.74998C15.4616 6.92777 14.7014 6.23601 13.8265 5.71674C12.9516 5.19747 11.9802 4.86154 10.9714 4.72937C9.96257 4.5972 8.93743 4.67155 7.95826 4.9479L6.64326 3.6329C7.68242 3.22457 8.81492 2.99957 9.99826 2.99957C14.4916 2.99957 18.2299 6.2329 19.0141 10.4996C18.7587 11.8876 18.1848 13.1976 17.3374 14.3262ZM9.76742 6.75623C10.2978 6.72345 10.8292 6.80379 11.3262 6.99191C11.8232 7.18004 12.2746 7.47166 12.6504 7.84744C13.0262 8.22323 13.3178 8.67459 13.5059 9.17161C13.694 9.66864 13.7744 10.2 13.7416 10.7304L9.76659 6.75623H9.76742Z'
        // fill='#5D6E82'
        fill='currentColor'
      />
    </svg>
  ),
);
