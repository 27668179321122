import { TFunction } from '@wyz/types';
import { FullPageSpinner } from '@wyz/ui';
import * as React from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { I18n } from '~/common/helpers';
import { TranslationKeys } from '~/common/types';

export function I18nProvider(props: React.PropsWithChildren) {
  const [initialized, setInitialized] = React.useState(false);
  const instance = I18n.getInstance();
  React.useLayoutEffect(() => {
    I18n.init()
      .then(() => {
        setInitialized(true);
      })
      .catch(() => {});
  }, []);

  if (!initialized) {
    return <FullPageSpinner />;
  }

  return <I18nextProvider i18n={instance}>{props.children}</I18nextProvider>;
}

type TranslationReturn = Omit<
  ReturnType<typeof useTranslation<'common' | 'settings' | 'orders'>>,
  't'
> & {
  t: TFunction<TranslationKeys>;
};

export const useI18n = (): TranslationReturn => {
  return useTranslation(['common', 'settings', 'orders']);
};
