import { RecordValues } from '@wyz/types';
import * as React from 'react';

type ConfirmApiInstance = {
  onConfirm: (cb: () => void) => ConfirmApiInstance;
  onCancel: (cb: () => void) => ConfirmApiInstance;
};
const ConfirmEnum = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
} as const;

type ConfirmType = RecordValues<typeof ConfirmEnum>;
export interface ConfirmOptions {
  confirmBtnVariant?: ConfirmType;
  title?: string;
  description?: React.ReactNode;
  customButtons?: (args: {
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
  }) => React.ReactNode;
  style?: React.CSSProperties;
  onOpen?: (onClose: () => void) => void;
  confirmBtnText: string;
  cancelBtnText?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
  backdrop?: true | false | 'static';
  size?: 'sm' | 'lg' | 'xl' | 'fullscreen';
  closeButton?: boolean;
  confirmBtnClass?: string;
  cancelBtnClass?: string;
  closeBtnClass?: string;
}
export type IConfirmationDispatcher = (
  options: ConfirmOptions,
) => ConfirmApiInstance;

export const ConfirmationContext = React.createContext<
  IConfirmationDispatcher | undefined
>(undefined);
