/* eslint-disable @wyz/i18n/no-plain-text */
import { Button, Tooltip } from '@wyz/ui';
import { useTheme } from '~/common/context';
const ToggleButton = () => {
  const { isNavbarVerticalCollapsed, setConfig } = useTheme();

  const handleClick = () => {
    document
      .getElementsByTagName('html')[0]
      .classList.toggle('navbar-vertical-collapsed');
    setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
  };

  return (
    <Tooltip label='Toggle Navigation'>
      <div className='toggle-icon-wrapper d-none d-xl-inline'>
        <Button
          id='toggle-menu'
          variant='link'
          // className='navbar-toggler-humburger-icon navbar-vertical-toggle'
          // id='toggleNavigationTooltip'
          onClick={handleClick}
        >
          <span className='navbar-toggle-icon'>
            <span className='toggle-line' />
          </span>
        </Button>
      </div>
    </Tooltip>
  );
};

export default ToggleButton;
