const TENANT_KEY = '__WYZ_TENANT__';
export const ACCESS_TOKEN_KEY = '__WYZ_ACCESS_TOKEN__';
export const API_URL_KEY = '__WYZ_API_URL__';
export const authHandlers = () => {
  return {
    setToken: (token: string) =>
      sessionStorage.setItem(ACCESS_TOKEN_KEY, token),
    getToken: () => sessionStorage.getItem(ACCESS_TOKEN_KEY),
    setTenant: (tenant: string) => sessionStorage.setItem(TENANT_KEY, tenant),
    getTenant: () => sessionStorage.getItem(TENANT_KEY),
  };
};
