import clsx from 'clsx';
import * as React from 'react';
import {
  Modal as BModal,
  CloseButton,
  ModalHeaderProps,
  ModalProps,
} from 'react-bootstrap';
import ModalContext from 'react-bootstrap/ModalContext';

type ContainerProps = {
  onClose: () => void;
  open: boolean;
} & React.PropsWithChildren<
  Pick<ModalProps, 'fullscreen' | 'keyboard' | 'backdrop' | 'size' | 'centered'>
>;
function Container({ children, open, onClose, ...rest }: ContainerProps) {
  return (
    <BModal show={open} onHide={onClose} contentClassName='border' {...rest}>
      {children}
    </BModal>
  );
}

function Title(props: React.PropsWithChildren) {
  return <BModal.Title as='h5'> {props.children}</BModal.Title>;
}
function Header({
  variant = 'light',
  closeButton = true,
  closeBtnClass = '',
  children,
}: React.PropsWithChildren<{ variant?: 'light' | 'white' }> &
  Pick<ModalHeaderProps, 'closeButton'> & { closeBtnClass?: string }) {
  const context = React.useContext(ModalContext);

  const handleClick = () => {
    context?.onHide();
  };

  return (
    <div
      style={{ padding: '1rem' }}
      className={clsx(
        'px-x1 border-bottom-0 justify-content-between d-flex align-items-center',
        {
          'bg-light': variant === 'light',
          'bg-white': variant === 'white',
        },
      )}
    >
      {children}
      {closeButton && (
        <CloseButton onClick={handleClick} className={clsx(closeBtnClass)} />
      )}
    </div>
  );
}
function Body({
  bg = 'neutral-grey-2',
  ...rest
}: React.PropsWithChildren<{ bg?: 'bs-default' | 'neutral-grey-2' }>) {
  return (
    <BModal.Body
      className={clsx('p-x1', {
        'bg-neutral-grey-2': bg === 'neutral-grey-2',
      })}
    >
      {rest.children}
    </BModal.Body>
  );
}
function Footer({
  variant = 'light',
  children,
}: React.PropsWithChildren<{ variant?: 'light' | 'white' }>) {
  return (
    <BModal.Footer
      className={clsx('p-x1 border-top-0', {
        'bg-light': variant === 'light',
        'bg-white': variant === 'white',
      })}
    >
      {children}
    </BModal.Footer>
  );
}

const Modal = {
  Container,
  Title,
  Header,
  Body,
  Footer,
};
export default Modal;
