import { useDatatableContext } from './context';
import PaginationBar from './pagination-bar';

const PaginationAction = () => {
  const { internals } = useDatatableContext();

  function handlePageChange(page: number) {
    internals.setter((prevState) => ({ ...prevState, page }));
  }

  return (
    <PaginationBar
      itemsPerPage={internals.state.itemsPerPage}
      total={internals.query.data?.meta.total}
      page={internals.state.page}
      onPageChange={handlePageChange}
    />
  );
};

export default PaginationAction;
