import * as React from 'react';

export const GasStationLineIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
      ref={ref}
    >
      <path
        d='M14 19H15V21H2V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4V12H16C16.5304 12 17.0391 12.2107 17.4142 12.5858C17.7893 12.9609 18 13.4696 18 14V18C18 18.2652 18.1054 18.5196 18.2929 18.7071C18.4804 18.8946 18.7348 19 19 19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18V11H18C17.7348 11 17.4804 10.8946 17.2929 10.7071C17.1054 10.5196 17 10.2652 17 10V6.414L15.343 4.757L16.757 3.343L21.707 8.293C21.8 8.38574 21.8738 8.49594 21.924 8.61727C21.9743 8.7386 22.0001 8.86866 22 9V18C22 18.7956 21.6839 19.5587 21.1213 20.1213C20.5587 20.6839 19.7956 21 19 21C18.2044 21 17.4413 20.6839 16.8787 20.1213C16.3161 19.5587 16 18.7956 16 18V14H14V19ZM5 19H12V13H5V19ZM5 5V11H12V5H5Z'
        fill='#5D6E82'
      />
    </svg>
  ),
);
