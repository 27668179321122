import { z } from 'zod';

export const VEHICLE_TYPE_GROUP_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const baseVehicleGroupType = z.object({
  id: z.string(),
  name: z.string(),
});
