import { Nav } from '@wyz/ui';
// eslint-disable-next-line @wyz/module/no-relative-import
import ProfileDropdown from './profile-dropdown';

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className='navbar-nav-icons ms-auto flex-row align-items-center'
      as='ul'
    >
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
