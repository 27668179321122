import { Flex, Icon } from '@wyz/ui';
import { useI18n } from '~/common/context';
import { RouteDefinition, TranslationKeys } from '~/common/types';

type NavbarVerticalMenuItemProps = {
  route: RouteDefinition<keyof TranslationKeys>;
};

const NavbarVerticalMenuItem = ({ route }: NavbarVerticalMenuItemProps) => {
  const { t } = useI18n();
  return (
    <Flex alignItems='center'>
      {route.icon && (
        <span className='nav-link-icon'>
          <Icon icon={route.icon} width={20} height={20} />
        </span>
      )}
      <Flex className='w-100' justifyContent='between'>
        <span className='nav-link-text ps-1'>{t(route.name)} </span>
        {route.externalTarget && (
          <Icon icon={'share-box'} width={20} height={20} />
        )}
      </Flex>
    </Flex>
  );
};

export default NavbarVerticalMenuItem;
