import * as React from 'react';
import { useI18n } from '~/common/context';
import { Datatable } from '@wyz/ui';
export function DatatableProvider(props: React.PropsWithChildren) {
  const { t } = useI18n();
  return (
    <Datatable.ConfigurationProvider
      translations={{
        resetPlaceholder: t('app.common.buttons.filter.reset'),
        searchBtn: t('app.common.buttons.search'),
        pagination: {
          itemsPerPageSelect: t(
            'app.common.datatable.pagination.items_per_page_select',
          ),
        },
      }}
    >
      {props.children}
    </Datatable.ConfigurationProvider>
  );
}
