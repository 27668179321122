import * as React from 'react';

export const BoxLineIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M10.0001 1.66602C9.71643 1.66597 9.43288 1.73348 9.17489 1.86875H9.17326L1.66675 5.42071V5.94458V14.5796L9.17489 18.1299C9.6905 18.4001 10.3093 18.4005 10.8253 18.1299H10.8269L18.3334 14.578V5.42071L10.8253 1.86875C10.5675 1.73366 10.2837 1.66606 10.0001 1.66602ZM9.95125 3.33819C9.98164 3.32223 10.0183 3.32132 10.0505 3.33819L10.0652 3.3463L15.4949 5.91539L13.8494 6.63551L8.27645 4.1313L9.93661 3.3463L9.95125 3.33819ZM6.29891 5.06714L11.7953 7.53404L10.075 8.28661L10.0505 8.29958C10.0183 8.31646 9.98163 8.31717 9.95125 8.3012L9.92521 8.28661L4.50529 5.91539L6.29891 5.06714ZM3.33341 7.21615L9.16675 9.76902V16.2891L3.33341 13.5302V7.21615ZM16.6667 7.21615V13.5286L10.8334 16.2874V9.76902L16.6667 7.21615Z'
        fill='currentColor'
        // fill='#113481'
      />
    </svg>
  ),
);
