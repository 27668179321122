import * as React from 'react';

type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type BreakPointRecord = Record<BreakPoint, number>;
type ThemeUpdater = (field: ThemeKey, value: string | boolean) => void;
type ITheme = {
  isFluid: boolean;
  isRTL: boolean;
  isDark: boolean;
  navbarPosition: 'vertical' | 'top' | 'combo' | 'double-top';
  showBurgerMenu: boolean; // controls showing vertical nav on mobile
  currency: '$';
  isNavbarVerticalCollapsed: boolean; // toggle vertical navbar collapse
  navbarStyle: 'card' | 'transparent' | 'inverted' | 'vibrant';
  topNavbarBreakpoint: BreakPoint;
  navbarBreakPoint: BreakPoint;
  navbarCollapsed: boolean;
  showSettingPanel: boolean;
  breakpoints: BreakPointRecord;
  setConfig: ThemeUpdater;
};

const DEFAULT_THEME: ITheme = {
  isFluid: true,
  isRTL: false,
  isDark: false, // getFromStore('isDark', false),
  navbarPosition: 'vertical', // getFromStore('navbarPosition', 'vertical'),
  navbarStyle: 'inverted', // getFromStore('navbarStyle', 'card'),
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  topNavbarBreakpoint: 'lg',
  navbarBreakPoint: 'xl',
  navbarCollapsed: false,
  showSettingPanel: false,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1540,
  },
  setConfig: (() => {}) as ThemeUpdater,
};
type ThemeKey = keyof ITheme;
const ThemeContext = React.createContext<ITheme>(DEFAULT_THEME);

const useToggleStyle = (
  isDark: ITheme['isDark'],
  configDispatch: React.Dispatch<IThemeAction>,
) => {
  React.useEffect(() => {
    document.documentElement.classList[isDark ? 'add' : 'remove']('dark');
    configDispatch({
      type: 'REFRESH',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDark]);
};

type IThemeAction = {
  type: 'SET_CONFIG' | 'REFRESH' | 'RESET';
  payload?: {
    key: ThemeKey;
    value: unknown;
    setInStore: boolean;
  };
};
const configReducer = (state: ITheme, action: IThemeAction) => {
  const { type, payload } = action;
  if (type === 'SET_CONFIG' && payload) {
    // if (payload.setInStore) {
    //   setToStore(payload.key, payload.value);
    // }
    return {
      ...state,
      [payload.key]: payload.value,
    };
  } else if (type === 'REFRESH')
    return {
      ...state,
    };
  else if (type === 'RESET') {
    localStorage.clear();
    return {
      ...DEFAULT_THEME,
      ...state,
    };
  } else return state;
};

export function ThemeProvider(props: React.PropsWithChildren) {
  const [config, configDispatch] = React.useReducer(
    configReducer,
    DEFAULT_THEME,
  );

  useToggleStyle(config.isDark, configDispatch);

  const setConfig: ThemeUpdater = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore:
          (
            [
              'isFluid',
              'isRTL',
              'isDark',
              'navbarPosition',
              'isNavbarVerticalCollapsed',
              'navbarStyle',
            ] as Array<ThemeKey>
          ).indexOf(key) !== 1,
      },
    });
  };

  const context: ITheme = React.useMemo(
    () => ({ ...config, setConfig }),
    [config],
  );

  return (
    <ThemeContext.Provider value={context}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeContext');
  }
  return context;
};
