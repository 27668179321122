import * as React from 'react';

export const SurveyFillIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <g clipPath='url(#clip0_2343_720)'>
        <path
          d='M5 3.33341V6.66675H15V3.33341H16.6725C17.1292 3.33341 17.5 3.70425 17.5 4.16091V17.5059C17.5 17.9626 17.1292 18.3334 16.6725 18.3334H3.3275C2.87083 18.3334 2.5 17.9626 2.5 17.5059V4.16091C2.5 3.70425 2.87083 3.33341 3.3275 3.33341H5ZM7.5 14.1667H5.83333V15.8334H7.5V14.1667ZM7.5 11.6667H5.83333V13.3334H7.5V11.6667ZM7.5 9.16675H5.83333V10.8334H7.5V9.16675ZM13.3333 1.66675V5.00008H6.66667V1.66675H13.3333Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2343_720'>
          <rect width='20' height='20' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  ),
);
