import { useQuery } from '@wyz/utils';
import { useState } from 'react';

import type {
  IErrorApiResponse,
  IFilter,
  IPaginationResponse,
  ISorter,
} from '@wyz/types';
import {
  DatatableProps,
  DatatableState,
  RowModel,
  UseDatatableResult,
} from './types';
import { formToCondition, transformCriteria } from './utils';
import { isNil } from 'lodash-es';
type Options<DataItem extends RowModel, TCriteria = IFilter> = Pick<
  DatatableProps<DataItem, TCriteria>,
  'fetcher' | 'defaults' | 'queryKey' | 'queryTransformer' | 'columns'
>;

const DEFAULT_SORTER: ISorter = {
  id: 'desc',
};

const DEFAULT_FILTER: DatatableState['criteria'] = {
  conditions: [],
  operator: 'or',
};

export const DEFAULT_PER_PAGE = 20;

type InitialDatatableState = Omit<DatatableState, 'transformedCriteria'>;
const INITIAL_STATE: InitialDatatableState = {
  itemsPerPage: DEFAULT_PER_PAGE,
  page: 1,
  sort: DEFAULT_SORTER,
  criteria: DEFAULT_FILTER,
  visibleColumns: [],
};

const useDatatable = <DataItem extends RowModel, TCriteria = IFilter>({
  fetcher,
  queryKey,
  defaults,
  columns,
  queryTransformer,
}: Options<DataItem, TCriteria>): UseDatatableResult<DataItem, TCriteria> => {
  const initialValue: InitialDatatableState = {
    ...INITIAL_STATE,
    ...defaults,
    //   visibleColumns: columns.map((column) => column.field),
  };

  const [paginatorState, setPaginatorState] = useState<
    DatatableState<TCriteria>
  >(() => {
    return {
      page: initialValue.page,
      itemsPerPage: initialValue.itemsPerPage,
      sort: {
        ...initialValue.sort,
        id: 'asc',
      },
      visibleColumns: [],
      criteria: initialValue.criteria,
      transformedCriteria: transformCriteria<TCriteria>(
        initialValue.criteria,
        queryTransformer,
      ),
    };
  });
  const query = useQuery<IPaginationResponse<DataItem>, IErrorApiResponse>({
    queryKey: [
      ...queryKey,
      paginatorState.page,
      paginatorState.itemsPerPage,
      paginatorState.sort,
      paginatorState.criteria,
    ],
    refetchOnMount: true,
    queryFn: () => {
      return fetcher({
        itemsPerPage: paginatorState.itemsPerPage,
        sort: paginatorState.sort,
        page: paginatorState.page,
        criteria: paginatorState.transformedCriteria,
      });
    },
    gcTime: 0,
    staleTime: 0,

    //   ...DEFAULT_QUERY_OPTIONS,
    //initialData: INITIAL_PAGINATION_RESPONSE<DataItem>(),
    // keepPreviousData: true,
  });
  const preSubmit: UseDatatableResult<DataItem, TCriteria>['preSubmit'] = (
    formData,
  ) => {
    const filterableColumns = columns.filter((column) => column.filterable);
    const criteria = {
      ...paginatorState.criteria,
      conditions: formToCondition(filterableColumns, formData),
      query:
        !isNil(formData?.searchQuery) && formData?.searchQuery !== ''
          ? formData?.searchQuery
          : undefined,
    };
    return {
      ...paginatorState,
      page: 1,
      criteria: criteria,
      transformedCriteria: transformCriteria<TCriteria>(
        criteria,
        queryTransformer,
      ),
    };
  };
  return {
    query,
    state: paginatorState,
    setter: setPaginatorState,
    preSubmit: preSubmit,
  };
};

export default useDatatable;
