import clsx from 'clsx';

import * as React from 'react';

const __DEFAULT_ELEMENT__ = 'div';
type TextOwnProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> = {
  component?: E;
  /**
   * @default 'normal'
   * thin: 100
   * lighter: 200
   * light: 300
   * normal: 400
   * medium: 500
   * semi-bold: 600
   * bold: 700
   * bolder: 800
   */
  weight?:
    | 'thin'
    | 'lighter'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semi-bold'
    | 'bold'
    | 'bolder';
  /**
   * @default 'xs'
   * xxs: fs-xxs : 0.625
   * xs: fs-xs 0.75
   * sm: fs-sm 0.875
   * normal: fs-base 1rem
   */
  heading?: 'xxs' | 'xs' | 'sm' | 'normal';
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'white'
    | 'secondary-gray'
    | 'tertiary-gray'
    | 'brand-blue-alt-normal'
    | 'info'
    | 'warning'
    | 'success'
    | 'inherit'
    | 'danger'
    | 'black'
    | 'tertiary-bold'
    | 'royal-blue';
  strikethrough?: boolean;
};

type TextProps<E extends React.ElementType> = TextOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TextOwnProps>;

function InnerText<T extends React.ElementType = typeof __DEFAULT_ELEMENT__>(
  {
    component: Component,
    id,
    isLoading,
    loaderProps,
    children,
    heading = 'xs',
    weight = 'normal',
    color = 'secondary',
    strikethrough,
    className, // className should NOT include any text size/weight classes
    ...rest
  }: TextProps<T>,
  ref: React.ForwardedRef<T>,
) {
  const ComponentToUse = Component || __DEFAULT_ELEMENT__;

  return (
    // @ts-expect-error: problem with forwardRef
    <ComponentToUse
      className={clsx(
        {
          'text-primary': color === 'primary',
          'text-title-color': color === 'secondary',
          'text-tertiary': color === 'tertiary',
          'text-tertiary-bold': color === 'tertiary-bold',
          'text-white': color === 'white',
          'text-neutral-grey-6': color === 'secondary-gray',
          'text-brand-blue-alt-normal': color === 'brand-blue-alt-normal',
          'text-royal-blue': color === 'royal-blue',
          'text-info-text': color === 'info',
          'text-success-text': color === 'success',
          'text-danger-text': color === 'danger',
          'text-warning-text': color === 'warning',
          'text-black': color === 'black',
          'fs-xxs': heading === 'xxs',
          'fs-xs': heading === 'xs',
          'fs-sm': heading === 'sm',
          'fs-normal': heading === 'normal',
          'fw-thin': weight === 'thin',
          'fw-lighter': weight === 'lighter',
          'fw-light': weight === 'light',
          'fw-normal': weight === 'normal',
          'fw-medium': weight === 'medium',
          'fw-semi-bold': weight === 'semi-bold',
          'fw-bold': weight === 'bold',
          'fw-bolder': weight === 'bolder',
          'text-decoration-line-through': strikethrough,
        },
        className,
      )}
      {...rest}
      id={id}
      data-cy={id}
      data-testid={id}
      ref={ref}
    >
      {children}
    </ComponentToUse>
  );
}

export default React.forwardRef(InnerText) as typeof InnerText;
