import * as React from 'react';
export const WarningTriangleIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <g clipPath='url(#clip0_9289_43083)'>
        <path
          d='M8.5761 2.5003L14.9268 13.5003C14.9853 13.6016 15.0161 13.7166 15.0161 13.8336C15.0161 13.9506 14.9853 14.0656 14.9268 14.167C14.8683 14.2683 14.7841 14.3525 14.6828 14.411C14.5814 14.4695 14.4665 14.5003 14.3494 14.5003H1.6481C1.53107 14.5003 1.41611 14.4695 1.31477 14.411C1.21342 14.3525 1.12927 14.2683 1.07076 14.167C1.01225 14.0656 0.981445 13.9506 0.981445 13.8336C0.981446 13.7166 1.01225 13.6016 1.07076 13.5003L7.42143 2.5003C7.47994 2.39896 7.5641 2.31481 7.66544 2.2563C7.76679 2.19779 7.88174 2.16699 7.99876 2.16699C8.11578 2.16699 8.23074 2.19779 8.33208 2.2563C8.43342 2.31481 8.51758 2.39896 8.5761 2.5003ZM2.80276 13.167H13.1948L7.99876 4.16696L2.80276 13.167ZM7.3321 11.167H8.66543V12.5003H7.3321V11.167ZM7.3321 6.5003H8.66543V9.83363H7.3321V6.5003Z'
          fill='#956113'
        />
      </g>
      <defs>
        <clipPath id='clip0_9289_43083'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  ),
);
