import * as React from 'react';
import {
  Button as BButton,
  ButtonProps as BButtonProps,
} from 'react-bootstrap';
import { Icon } from '../index';
import clsx from 'clsx';
import './button.scss';
const __DEFAULT_ELEMENT__ = BButton;
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'tertiary-blue'
  | 'tertiary-grey'
  | 'neutral'
  | 'outline'
  | 'outline-primary'
  | 'outline-secondary'
  | 'white-chip'
  | 'link'
  | 'light';

type ButtonOwnProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> =
  Omit<BButtonProps, 'as' | 'style' | 'variant' | 'size'> & {
    as?: E;
    variant: ButtonVariant;
    isLoading?: boolean;
    id: string;
    size?: 'xxs' | 'xs' | 'sm' | 'lg';
    fullWidth?: boolean;
    rounded?: boolean;
    extraClasses?: string;
  };

export type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ButtonOwnProps>;

function Button<T extends React.ElementType = typeof __DEFAULT_ELEMENT__>(
  {
    children,
    as,
    variant,
    size,
    id,
    isLoading,
    disabled,
    fullWidth,
    rounded,
    extraClasses,
    ...props
  }: ButtonProps<T>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const ComponentToUse = as || __DEFAULT_ELEMENT__;

  return (
    <ComponentToUse
      {...props}
      disabled={disabled || isLoading}
      id={id}
      data-cy={id}
      variant={variant}
      className={clsx(
        `${
          extraClasses || ''
        } d-flex justify-content-center align-items-center text-wrap position-relative btn btn-${variant} btn-${
          size || 'md'
        }`,
        {
          'w-100': fullWidth,
          'disabled': disabled,
          'btn-rounded': rounded,
        },
      )}
      ref={ref}
    >
      {children}
      {isLoading && (
        <div
          className='d-flex align-items-center position-absolute'
          style={{
            top: '50%',
            width: '100%',
            right: 0,
            transform: 'translateY(-50%)',
          }}
        >
          <Icon
            className='position-absolute'
            icon='spinner'
            style={{ width: 15, height: 15, right: '5%', opacity: 0.7 }}
          />
        </div>
      )}
    </ComponentToUse>
  );
}

export default React.forwardRef(Button) as typeof Button;

Button.displayName = 'Button';
