import { Col, Row } from '@wyz/ui';

const Footer = () => (
  <footer className='footer'>
    <Row className='justify-content-between text-center fs-xxs mt-4 mb-3'>
      <Col sm='auto'>
        <p className='mb-0 text-600' translate='no'>
          Copyright
          <span className='d-none d-sm-inline-block' translate='no'>
            |
          </span>
          <br className='d-sm-none' /> {new Date().getFullYear()} &copy;{' '}
          <a href='https://www.wyzgroup.com/en/' translate='no'>
            WYZ
          </a>
        </p>
      </Col>
      <Col sm='auto'>
        <p className='mb-0 text-600' translate='no'>
          v2
        </p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
