import * as React from 'react';
import clsx from 'clsx';
import {
  Button,
  ConfirmOptions,
  ConfirmationContext,
  Flex,
  IConfirmationDispatcher,
  Modal,
} from '../index';

const DEFAULT_OPTIONS: ConfirmOptions & {
  title: string;
  confirmBtnVariant: ConfirmOptions['confirmBtnVariant'];
  confirmBtnText?: string;
  cancelBtnText?: string;
  showCancel: boolean;
} = {
  confirmBtnVariant: undefined,
  title: 'Are you sure ?',
  confirmBtnText: 'Confirm',
  showCancel: true,
  showConfirm: true,
  showCloseButton: false,
  closeOnClickOutside: true,
  confirmBtnClass: undefined,
  cancelBtnClass: undefined,
  closeBtnClass: undefined,
};

const ConfirmationProvider = ({
  children,
  ...rest
}: React.PropsWithChildren) => {
  // const { t } = useI18n();
  const cancelButtonRef = React.useRef<HTMLButtonElement>(null);

  const [{ customButtons, ...restOptions }, setOptions] =
    React.useState<ConfirmOptions>(DEFAULT_OPTIONS);
  const [resolveReject, setResolveReject] = React.useState<
    [resolve?: (value?: unknown) => void, reject?: (reason?: unknown) => void]
  >([]);
  const [resolve, reject] = resolveReject;

  const handleClose = React.useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = React.useCallback(() => {
    reject?.();
    handleClose();
  }, [reject, handleClose]);

  const handleConfirm = React.useCallback(() => {
    resolve?.();
    handleClose();
  }, [resolve, handleClose]);

  const confirm: IConfirmationDispatcher = React.useCallback(
    (options: Partial<ConfirmOptions> = {}) => {
      const { onOpen, ...rest } = options;
      onOpen?.(handleConfirm);

      const promise = new Promise((resolve, reject) => {
        setOptions({ ...DEFAULT_OPTIONS, ...rest });
        setResolveReject([resolve, reject]);
        setTimeout(() => {
          cancelButtonRef.current?.focus();
        });
      });
      const api: ReturnType<IConfirmationDispatcher> = {
        onConfirm: (cb) => {
          promise
            .then(() => {
              cb();
            })
            .catch(() => {});
          return api;
        },
        onCancel: (cb) => {
          promise.catch(() => {
            cb();
          });
          return api;
        },
      };

      return api;
    },
    [handleConfirm],
  );

  return (
    <ConfirmationContext.Provider value={confirm} {...rest}>
      {children}
      {resolveReject.length === 2 ? (
        <Modal.Container
          open
          onClose={handleCancel}
          backdrop={restOptions.backdrop ?? 'static'}
          keyboard={false}
          {...(restOptions.size === 'fullscreen'
            ? { fullscreen: true }
            : { size: restOptions.size })}
        >
          <Modal.Header
            closeButton={restOptions.closeButton ?? true}
            closeBtnClass={restOptions.closeBtnClass}
          >
            <Modal.Title>
              {restOptions.title ?? DEFAULT_OPTIONS.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body data-cy='confirmation-modal'>
            {restOptions.description}
          </Modal.Body>
          <Modal.Footer>
            <Flex className='w-100 space-x-4'>
              {customButtons?.({
                onClose: handleClose,
                onConfirm: handleConfirm,
                onCancel: handleCancel,
              }) || (
                <>
                  {restOptions.cancelBtnText && (
                    <Button
                      ref={cancelButtonRef}
                      id='cancel-modal'
                      variant='secondary'
                      className={clsx('mx-2', restOptions.cancelBtnClass)}
                      onClick={handleCancel}
                      fullWidth
                    >
                      {restOptions.cancelBtnText}
                    </Button>
                  )}
                  <Button
                    id='submit-modal'
                    variant={restOptions.confirmBtnVariant || 'primary'}
                    type='submit'
                    extraClasses={restOptions.confirmBtnClass}
                    onClick={handleConfirm}
                    fullWidth
                  >
                    {restOptions.confirmBtnText}
                  </Button>
                </>
              )}
            </Flex>
          </Modal.Footer>
        </Modal.Container>
      ) : undefined}
    </ConfirmationContext.Provider>
  );
};

function useConfirmation(): IConfirmationDispatcher {
  const context = React.useContext(ConfirmationContext);
  if (context === undefined) {
    throw new Error('useConfirm must be used within a ConfirmationProvider');
  }
  return context;
}

export { ConfirmationProvider, useConfirmation };
