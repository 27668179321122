import { useMemo, useState } from 'react';

type Return<T> = [
  Array<T>,
  {
    append: (item: T, callback?: PostActionCallBack<T>) => void;
    reset: (values?: Array<T>, callback?: PostActionCallBack<T>) => void;
    removeByIndex: (index: number, callback?: PostActionCallBack<T>) => void;
    removeByItem: (item: T, callback?: PostActionCallBack<T>) => void;
    move: (from: number, to: number) => void;
  },
];
type PostActionCallBack<T> = (values: Array<T>) => void;
const useArray = <T>(initialValue: Array<T> = []): Return<T> => {
  const [array, setArray] = useState<Array<T>>(initialValue);

  const handlers: Return<T>[1] = useMemo(
    () => ({
      append: (item, cb) => {
        setArray((prev) => {
          const nextValue = [...prev, item];
          cb?.(nextValue);
          return nextValue;
        });
      },
      reset: (values, cb) => {
        setArray(values ?? initialValue);
        cb?.(initialValue);
      },
      removeByIndex: (index, cb) => {
        setArray((prev) => {
          const nextValue = prev.filter((_, i) => i !== index);
          cb?.(nextValue);
          return nextValue;
        });
      },
      removeByItem: (itemToRemove, cb) => {
        setArray((prev) => {
          const nextValue = prev.filter((item) => item !== itemToRemove);
          cb?.(nextValue);
          return nextValue;
        });
      },
      move: (from, to) => {
        setArray((prev) => {
          const nextValue = [...prev];
          const [removed] = nextValue.splice(from, 1);
          nextValue.splice(to, 0, removed);
          return nextValue;
        });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [array, handlers];
};

export default useArray;
