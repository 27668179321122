import * as React from 'react';

export const SettingsIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <g clipPath='url(#clip0_2343_667)'>
        <path
          d='M8.29341 1.84152C9.41752 1.6058 10.5782 1.60523 11.7026 1.83985C11.7775 2.33426 11.9628 2.80543 12.2449 3.21834C12.5269 3.63124 12.8984 3.97527 13.3317 4.22485C13.7647 4.4753 14.2486 4.62491 14.7473 4.66256C15.2461 4.70022 15.7469 4.62495 16.2126 4.44235C16.9778 5.29907 17.5575 6.30489 17.9151 7.39652C17.5245 7.70867 17.2093 8.10481 16.9929 8.55551C16.7765 9.00622 16.6645 9.49989 16.6651 9.99985C16.6651 11.0532 17.1534 11.9924 17.9167 12.6032C17.557 13.6939 16.9766 14.6989 16.2117 15.5557C15.7462 15.3732 15.2455 15.298 14.7469 15.3357C14.2483 15.3733 13.7646 15.5229 13.3317 15.7732C12.8988 16.0227 12.5276 16.3665 12.2457 16.7791C11.9638 17.1917 11.7785 17.6625 11.7034 18.1565C10.5794 18.3928 9.4187 18.3939 8.29424 18.1599C8.2197 17.665 8.0345 17.1933 7.75243 16.78C7.47036 16.3666 7.09869 16.0222 6.66508 15.7724C6.23205 15.522 5.74816 15.3726 5.2494 15.3351C4.75064 15.2976 4.24984 15.373 3.78425 15.5557C3.01891 14.6987 2.43925 13.6926 2.08174 12.6007C2.47207 12.2887 2.78713 11.8928 3.00352 11.4424C3.21992 10.992 3.3321 10.4987 3.33174 9.99902C3.33215 9.49892 3.21983 9.00516 3.00313 8.55445C2.78644 8.10374 2.47093 7.70767 2.08008 7.39569C2.4398 6.30496 3.02018 5.29994 3.78508 4.44319C4.25062 4.62565 4.7513 4.70085 5.24991 4.6632C5.74851 4.62554 6.23222 4.476 6.66508 4.22569C7.09803 3.97618 7.46923 3.63237 7.75113 3.21978C8.03302 2.80718 8.21837 2.33639 8.29341 1.84235V1.84152ZM9.99841 12.4999C10.6615 12.4999 11.2973 12.2365 11.7662 11.7676C12.235 11.2988 12.4984 10.6629 12.4984 9.99985C12.4984 9.33681 12.235 8.70093 11.7662 8.23209C11.2973 7.76325 10.6615 7.49985 9.99841 7.49985C9.33537 7.49985 8.69948 7.76325 8.23064 8.23209C7.7618 8.70093 7.49841 9.33681 7.49841 9.99985C7.49841 10.6629 7.7618 11.2988 8.23064 11.7676C8.69948 12.2365 9.33537 12.4999 9.99841 12.4999Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2343_667'>
          <rect width='20' height='20' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  ),
);
