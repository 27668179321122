import { authHandlers } from './auth';

const { fetch: originalFetch } = window;
export const HTTPErrorKind = {
  OK: 200,
  NoContent: 204,
  NotFound: 404,
  Validation: 422,
  Expired: 401,
  AccessDenied: 403,
  Server: 500,
} as const;

type GlobalConfig = {
  onExpire?: () => void;
  baseUrl?: string;
  isDebugModeEnabled?: boolean;
};

const DEBUG_HEADER = 'X-Wz-Debug-Mode';
export const DEBUG_QUERY_STRING = 'debug';
const TENANT_HEADER = 'x-wz-tenant';

export const callApi =
  (globalConfig: GlobalConfig) =>
  async <T>(
    input: RequestInfo | URL,
    init?: RequestInit & {
      authless?: boolean;
      asJson?: boolean;
      // zodSchema?: ZodSchema;
    },
  ): Promise<T> => {
    const { asJson = true, authless } = init || {};
    const customerHeaders: RequestInit['headers'] = {};
    if (!authless) {
      customerHeaders['Authorization'] = `Bearer ${authHandlers().getToken()}`;
    }
    if (authHandlers().getTenant()) {
      customerHeaders[TENANT_HEADER] = authHandlers().getTenant() as string;
    }
    if (asJson) {
      customerHeaders['Content-Type'] = 'application/json';
    }

    if (globalConfig.isDebugModeEnabled) {
      customerHeaders[DEBUG_HEADER] = 'true';
    }

    const response = await originalFetch(
      globalConfig.baseUrl && typeof input === 'string'
        ? `${globalConfig.baseUrl}${input}`
        : input,
      {
        ...init,
        credentials: 'same-origin',
        headers: {
          ...customerHeaders,
          ...init?.headers,
        },
      },
    );
    if (response.status === HTTPErrorKind.Expired) {
      globalConfig.onExpire?.();
      throw new Error('Expired token');
    }
    if (!response.ok) {
      throw await response.json();
    }
    if (response.status === HTTPErrorKind.NoContent) {
      return { data: null, errors: [], message: '' } as T;
    }
    const currentTenant = response.headers.get(TENANT_HEADER);
    if (currentTenant) {
      authHandlers().setTenant(currentTenant);
    }
    const apiResponse = await response.json();
    if (globalConfig.isDebugModeEnabled) {
      // eslint-disable-next-line no-console
      console.log(apiResponse.debug);
    }

    return apiResponse;
  };
