import * as React from 'react';

export const AddLineIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      {...props}
      ref={ref}
    >
      <path
        d='M9.16602 9.16669V4.16669H10.8327V9.16669H15.8327V10.8334H10.8327V15.8334H9.16602V10.8334H4.16602V9.16669H9.16602Z'
        fill='#113481'
      />
    </svg>
  ),
);
