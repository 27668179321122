import { isNil } from 'lodash-es';

const storage = {
  clear(): void {
    localStorage.clear();
  },
  set<T>(key: string, data: T): void {
    try {
      const serializedState = JSON.stringify(data);
      localStorage.setItem(key, serializedState);
    } catch {
      // Ignore write errors.
      localStorage.clear();
    }
  },

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch {
      // eslint-disable-next-line no-console
      console.log('Error Removing from local storage');
    }
  },

  get<T>(key: string): T | undefined {
    try {
      const data = localStorage.getItem(key);
      if (isNil(data)) {
        return undefined;
      }
      return JSON.parse(data);
    } catch {
      localStorage.clear();

      return undefined;
    }
  },
};

export default storage;
