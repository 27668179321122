import {
  API_URL_KEY,
  callApi,
  useOnSuccessQuery,
  usePersistedState,
  useQuery,
} from '@wyz/utils';
import { FullPageSpinner } from '@wyz/ui';
import * as React from 'react';
import { IConfig } from '~/common/types';
// eslint-disable-next-line @wyz/module/no-relative-import
import { ConfigContext } from './contexts';
export function ConfigProvider(props: React.PropsWithChildren) {
  const call = callApi({});
  const [configUrl, setConfigUrl] = usePersistedState<string>(API_URL_KEY, '');
  const globalConfig = useQuery<IConfig>({
    queryKey: ['global-config'],
    queryFn: () =>
      call<IConfig>('/config.json', {
        authless: true,
      }),
    gcTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
  useOnSuccessQuery(globalConfig, (config) => {
    setConfigUrl(config.apiUrl);
  });
  if (!globalConfig.isSettled) {
    return <FullPageSpinner />;
  }
  if (!globalConfig.isSuccess || !configUrl) {
    return <>Failed to fetch config</>;
  }
  return (
    <ConfigContext.Provider value={{ ...globalConfig.data, apiUrl: configUrl }}>
      {props.children}
    </ConfigContext.Provider>
  );
}
export const useConfig = () => {
  const context = React.useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigContext');
  }
  return context;
};
