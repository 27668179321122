import { useEffect } from 'react';

export default function useOnMount(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  fn: Function,
) {
  useEffect(() => {
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
