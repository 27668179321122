import * as React from 'react';

export const InformationLineIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M10.0003 18.3334C5.39783 18.3334 1.66699 14.6025 1.66699 10C1.66699 5.39752 5.39783 1.66669 10.0003 1.66669C14.6028 1.66669 18.3337 5.39752 18.3337 10C18.3337 14.6025 14.6028 18.3334 10.0003 18.3334ZM10.0003 16.6667C11.7684 16.6667 13.4641 15.9643 14.7144 14.7141C15.9646 13.4638 16.667 11.7681 16.667 10C16.667 8.23191 15.9646 6.53622 14.7144 5.28598C13.4641 4.03573 11.7684 3.33335 10.0003 3.33335C8.23222 3.33335 6.53652 4.03573 5.28628 5.28598C4.03604 6.53622 3.33366 8.23191 3.33366 10C3.33366 11.7681 4.03604 13.4638 5.28628 14.7141C6.53652 15.9643 8.23222 16.6667 10.0003 16.6667ZM9.16699 5.83335H10.8337V7.50002H9.16699V5.83335ZM9.16699 9.16669H10.8337V14.1667H9.16699V9.16669Z'
        fill='currentColor'
      />
    </svg>
  ),
);
