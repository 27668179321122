import * as React from 'react';

export const CloseIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        d='M10.0006 8.82031L14.1256 4.69531L15.3039 5.87365L11.1789 9.99865L15.3039 14.1236L14.1256 15.302L10.0006 11.177L5.8756 15.302L4.69727 14.1236L8.82227 9.99865L4.69727 5.87365L5.8756 4.69531L10.0006 8.82031Z'
        fill='currentColor'
      />
    </svg>
  ),
);
