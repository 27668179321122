import * as React from 'react';
import {
  IDatatableContext,
  IDatatableGlobalConfiguration,
  RowModel,
} from './types';
import { IFilter } from '@wyz/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let Context: React.Context<any> | undefined = undefined;
export function createDataTableContext<
  T extends RowModel,
  TCriteria = IFilter,
>(): React.Context<IDatatableContext<T, TCriteria>> {
  if (Context) {
    return Context;
  }
  Context = React.createContext<IDatatableContext<T> | undefined>(undefined);
  return Context;
}

export const useDatatableContext = <
  T extends RowModel,
  TCriteria = IFilter,
>() => {
  const context = React.useContext<IDatatableContext<T, TCriteria>>(
    createDataTableContext<T, TCriteria>(),
  );

  if (context === undefined) {
    throw new Error('useDatatableContext not used inside the DT context');
  }

  return context;
};

export const DatatableConfigurationContext = React.createContext<
  IDatatableGlobalConfiguration | undefined
>(undefined);

export const useGlobalConfiguration = () => {
  const context = React.useContext(DatatableConfigurationContext);

  return context || {};
};
