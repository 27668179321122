import {
  QueryClient,
  QueryKey,
  useMutation as _useMutation,
  UseMutationOptions as _UseMutationOptions,
  UseMutationResult as _UseMutationResult,
  useQuery as _useQuery,
  useQueryClient as _useQueryClient,
  UseQueryOptions as _UseQueryOptions,
  UseQueryResult as _UseQueryResult,
  DefaultError,
} from '@tanstack/react-query';
import { ArgumentAwareQueryKey, QueryQueryDefinition } from '@wyz/types';

import { useEffect } from 'react';

type QueryStatusType = 'active' | 'all' | 'inactive';
export type MutationAdditionalOptions<TVariables = void> = {
  keysToInvalidateOnSuccess?:
    | Array<QueryKey | { key: QueryKey; type: QueryStatusType }>
    | ((
        variables: TVariables,
      ) => Array<QueryKey | { key: QueryKey; type: QueryStatusType }>);
  enabled?: boolean;
};
export type UseMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = _UseMutationOptions<TData, TError, TVariables, TContext> &
  MutationAdditionalOptions<TVariables>;

export type UseQueryOptions<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = _UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

function useQueryClient(): QueryClient {
  return _useQueryClient();
}

type AdditionalQueryResult = {
  isSettled: boolean;
  isProcessing: boolean;
  isIdle: boolean;
};
type AdditionalMutationResult = {
  isLoading: boolean;
};

export type UseQueryResult<TData = unknown, TError = unknown> = _UseQueryResult<
  TData,
  TError
> &
  AdditionalQueryResult;

function useQuery<TData = unknown, TError = unknown>(
  options: UseQueryOptions<TData, TError>,
): UseQueryResult<TData, TError> {
  const query = _useQuery({
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  });
  const isSettled = query.isSuccess || query.isError;
  return {
    ...query,
    isIdle: !isSettled && query.fetchStatus === 'idle',
    isSettled,
    isProcessing: query.fetchStatus === 'fetching',
  };
}

export type UseMutationResult<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = _UseMutationResult<TData, TError, TVariables, TContext> &
  AdditionalMutationResult;

function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options?: UseMutationOptions<TData, TError, TVariables, TContext> &
    MutationAdditionalOptions<TVariables>,
): UseMutationResult<TData, TError, TVariables, TContext> &
  AdditionalMutationResult {
  const queryClient = useQueryClient();

  const mutation = _useMutation({
    ...options,
    onSuccess: (...args) => {
      if (options?.keysToInvalidateOnSuccess) {
        const keys =
          typeof options?.keysToInvalidateOnSuccess === 'function'
            ? options?.keysToInvalidateOnSuccess(args[1])
            : options?.keysToInvalidateOnSuccess;

        Promise.all(
          keys.map((key) =>
            queryClient.refetchQueries({
              queryKey: 'key' in key ? key.key : key,
              type: 'key' in key ? key.type : 'active',
            }),
          ),
        ).catch(() => {});
      }
      return options?.onSuccess?.(...args);
    },
  });

  return {
    ...mutation,
    isLoading: mutation.isPending,
  };
}

const DEFAULT_QUERY_OPTIONS = {
  refetchIntervalInBackground: false,
  refetchInterval: 1000 * 120,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: true, //default
};
const DEFAULT_NO_RETRY_QUERY_OPTIONS = {
  ...DEFAULT_QUERY_OPTIONS,
  retry: false,
  initialData: undefined,
  enabled: false,
  refetchInterval: 0,
  // gcTime: 0,
  refetchOnMount: false,
};
const DEFAULT_ONE_TIME_QUERY_OPTIONS = {
  ...DEFAULT_QUERY_OPTIONS,
  retry: false,
  initialData: undefined,
  enabled: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  refetchOnMount: true,
};

const NO_REFETCH_OPTIONS: Pick<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UseQueryOptions<any, any, any, any>,
  | 'refetchInterval'
  | 'refetchIntervalInBackground'
  | 'refetchOnMount'
  | 'refetchOnReconnect'
  | 'refetchOnWindowFocus'
> = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  refetchInterval: false,
  refetchIntervalInBackground: false,
};

export type OverridableQuery<T> = Omit<T, 'queryKey' | 'queryFn'>;

export const useOnSuccessQuery = <TData, TError>(
  query: UseQueryResult<TData, TError>,
  cb: (data: TData) => void,
) => {
  useEffect(() => {
    if (query.isSuccess && query.fetchStatus === 'idle') {
      cb(query.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.fetchStatus, query.data, query.isSuccess]);
};
export const useOnErrorQuery = <TData, TError>(
  query: UseQueryResult<TData, TError>,
  cb: (error: TError) => void,
) => {
  useEffect(() => {
    if (query.isError && query.fetchStatus === 'idle') {
      cb(query.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.fetchStatus, query.error, query.isError]);
};
export {
  QueryClient,
  QueryClientProvider,
  useQueries,
} from '@tanstack/react-query';
export {
  useQuery,
  useMutation,
  useQueryClient,
  DEFAULT_QUERY_OPTIONS,
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  DEFAULT_NO_RETRY_QUERY_OPTIONS,
  NO_REFETCH_OPTIONS,
};

export const queryKeyFactory = <
  T extends Record<string, ArgumentAwareQueryKey>,
>(
  queryKeys: QueryQueryDefinition<T>,
): QueryQueryDefinition<T> => queryKeys;

export const restrictApi = <TRequestedOptions, TFullApi>(
  _: TRequestedOptions,
  fullApi: TFullApi,
) => {
  type FullApiKey = keyof TFullApi;
  type AllowedKeys = keyof TRequestedOptions & FullApiKey;

  return fullApi as Pick<TFullApi, AllowedKeys>;
};

export const emptyQueryError = () =>
  Promise.reject(new Error('Missing required query params'));
