import * as React from 'react';
import { IFormConfigurationContext } from '@wyz/types';
import { FormContext } from './contexts';

export function ConfiguredFormProvider(
  props: React.PropsWithChildren<IFormConfigurationContext>,
) {
  return (
    <FormContext.Provider value={props}>{props.children}</FormContext.Provider>
  );
}

export const useConfiguredForm = () => {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormContext');
  }
  return context;
};
