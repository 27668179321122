import { Button, Flex } from '../index';
import { DEFAULT_PER_PAGE } from './hook';

function inBetween(nb: number, min: number, max: number) {
  return nb >= min && nb <= max;
}

const PreviousIcon = () => (
  <svg
    width={15}
    height={15}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
    aria-hidden='true'
  >
    <path
      fillRule='evenodd'
      d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
      clipRule='evenodd'
    />
  </svg>
);

const NextIcon = () => (
  <svg
    width={15}
    height={15}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
    aria-hidden='true'
  >
    <path
      fillRule='evenodd'
      d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
      clipRule='evenodd'
    />
  </svg>
);

type Props = {
  itemsPerPage?: number;
  total?: number;
  page?: number;
  onPageChange: (page: number) => void;
};
const PaginationBar = (props: Props) => {
  const perPage = props.itemsPerPage ?? DEFAULT_PER_PAGE;
  const total = props.total ?? 2;
  const page = props.page ?? 1;
  const nbOfPages = Math.ceil(total / perPage);
  const isNextButtonDisabled = page === nbOfPages || total === 0;
  const isPreviousButtonDisabled = page <= 1;

  return (
    <Flex alignItems='center' justifyContent='center' className='my-2'>
      <Button
        id='prev-page'
        size='sm'
        variant='outline'
        disabled={page <= 1}
        onClick={() =>
          isPreviousButtonDisabled ? {} : props.onPageChange(page - 1)
        }
      >
        <PreviousIcon />
      </Button>
      <ul className='pagination mb-0 mx-2'>
        {Array.from({ length: nbOfPages })
          .fill(0)
          .map((_, index) => index + 1)
          .map((currentIndex) => {
            if (
              currentIndex === 1 ||
              currentIndex === nbOfPages ||
              inBetween(currentIndex, page - 1, page + 1)
            )
              return (
                <Button
                  key={currentIndex}
                  id={`page-${currentIndex}`}
                  size='sm'
                  variant={page === currentIndex ? 'primary' : 'outline'}
                  // className={clsx('page', {
                  //   'bg-primary text-white': page === currentIndex,
                  // })}
                  onClick={() => props.onPageChange(currentIndex)}
                >
                  {currentIndex}
                </Button>
              );

            if (currentIndex === page - 2 || currentIndex === page + 2) {
              return (
                <span
                  key={currentIndex}
                  className='position-relative d-inline-flex align-items-center border px-2 me-2'
                >
                  ...
                </span>
              );
            }

            return undefined;
          })}
      </ul>
      <Button
        id='next-page'
        size='sm'
        variant='outline'
        disabled={isNextButtonDisabled}
        onClick={() =>
          isNextButtonDisabled ? {} : props.onPageChange(page + 1)
        }
      >
        <NextIcon />
      </Button>
    </Flex>
  );
};

export default PaginationBar;
