import * as React from 'react';
import { GroupBase, OnChangeValue } from 'react-select';

import {
  useDebounce,
  useQueries,
  isArrayOfString,
  useQueryClient,
  UseQueryOptions,
} from '@wyz/utils';
import { ISelectOption, Select, SelectProps } from '../select';
import { isNil, isObject } from 'lodash-es';
import { ICancellationOptions } from '@wyz/types';

export type TypeAheadFetcher<Option> = (
  query: string,
  options: ICancellationOptions,
) => Promise<Array<Option>>;

type PrivateTypeAheadProps<
  IsMulti extends boolean,
  Option extends ISelectOption = ISelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Pick<
  SelectProps<IsMulti, Option, Group>,
  | 'isClearable'
  | 'required'
  | 'id'
  | 'isDisabled'
  | 'name'
  | 'isError'
  | 'isMulti'
  | 'placeholder'
  | 'size'
  | 'additionalQueryKeys'
  | 'showCount'
> & {
  fetcher: TypeAheadFetcher<Option> | Array<TypeAheadFetcher<Option>>;
  defaultValue?: Option | Array<Option>;
  value?: Option | Array<Option> | string | Array<string>;
  onChange: (args: OnChangeValue<Option, IsMulti>) => void;
  minCharacter?: number;
  minCharacterMessage?: string;
  mapper?: (data: Array<Array<Option> | undefined>) => Array<Option | Group>;
};

export type PublicTypeAheadFieldProps<
  IsMulti extends boolean,
  Option extends ISelectOption = ISelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  TKeys extends string = string,
> = Pick<
  PrivateTypeAheadProps<IsMulti, Option, Group>,
  | 'fetcher'
  | 'id'
  | 'isDisabled'
  | 'isClearable'
  | 'placeholder'
  | 'isMulti'
  | 'name'
  | 'required'
  | 'minCharacter'
  | 'mapper'
  | 'defaultValue'
  | 'showCount'
  | 'size'
  | 'additionalQueryKeys'
  | 'minCharacterMessage'
> & {
  helperText?: string;
  label?: string;
  translate: (key: TKeys) => string;
  /**
   * Only used to notify the parent about change, NOT to control the component
   */
  onSelect?: (args: OnChangeValue<Option, IsMulti>) => void;
  maxShown?: number;
};

const DEFAULT_MIN_CHARACTER = 0;
const DEBOUNCE_TIME = 700;

const flattenOptions = <T extends ISelectOption>(
  options: Array<T | GroupBase<T>>,
) => {
  const flatten: Array<T> = [];
  options.forEach((option) => {
    if ('options' in option) {
      flatten.push(...option.options);
    } else {
      flatten.push(option);
    }
  });
  return flatten;
};

const COMMON_OPTIONS = {
  staleTime: Infinity,
  gcTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: false,
};
function TypeAhead<
  IsMulti extends boolean,
  Option extends ISelectOption = ISelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  defaultValue,
  fetcher,
  name,
  onChange,
  value,
  id,
  isMulti,
  isDisabled,
  minCharacter = DEFAULT_MIN_CHARACTER,
  mapper = (data) => data[0] || [],
  placeholder = 'Saisissez les premiers caractères',
  minCharacterMessage,
  showCount = true,
  additionalQueryKeys = [],
  ...rest
}: PrivateTypeAheadProps<IsMulti, Option>) {
  const [inputValue, setInputValue] = React.useState('');
  const prevValue = React.useRef('');
  const queryClient = useQueryClient();

  const queries = useQueries({
    queries: Array.isArray(fetcher)
      ? (fetcher.map((queryFn, index) => ({
          ...COMMON_OPTIONS,
          queryKey: [name, inputValue, index, ...additionalQueryKeys],
          queryFn: ({ signal }) => {
            return queryFn(inputValue, { signal });
          },
        })) as Array<UseQueryOptions<Array<Option>>>)
      : [
          {
            ...COMMON_OPTIONS,
            queryKey: [name, inputValue, ...additionalQueryKeys],
            queryFn: ({ signal }) => fetcher(inputValue, { signal }),
          },
        ],
  });

  useDebounce(
    () => {
      if (inputValue.length >= minCharacter) {
        queries.forEach((query) => {
          if (prevValue.current)
            queryClient
              .cancelQueries({ queryKey: [name, prevValue.current] })
              .catch(() => '');

          query.refetch().catch(() => {});
        });
        prevValue.current = inputValue;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
    DEBOUNCE_TIME,
  );
  const options = mapper(queries.map((query) => query.data)) as Array<
    Option | Group
  >;
  const getValue = (selected: typeof value): Array<Option> | Option | null => {
    if (isNil(selected)) {
      // niente
      return selected ?? null;
    } else if (Array.isArray(selected)) {
      if (selected.length === 0) {
        // empty
        return selected as Array<Option>;
      } else if (isArrayOfString(selected)) {
        // array of string
        return flattenOptions(options).filter((item) =>
          selected.includes(item.value),
        );
      } else {
        // array of object
        return selected;
      }
    } else if (isObject(selected)) {
      return selected;
    } else if (typeof selected === 'string') {
      return (
        flattenOptions(options).find((item) => item.value === selected) ?? null
      );
    } else {
      return null;
    }
  };
  const currentValue = getValue(value);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = React.useRef<any>(null);
  // Restore input value.
  const onFocus = () => {
    if (currentValue && !Array.isArray(currentValue)) {
      selectRef.current.handleInputChange({
        currentTarget: { value: currentValue.label },
      });
    }
  };

  const canShowCount = Boolean(
    showCount &&
      (!minCharacterMessage || (minCharacterMessage && options.length > 0)),
  );
  return (
    <>
      <Select<IsMulti, Option, Group>
        {...rest}
        ref={selectRef}
        placeholder={placeholder}
        name={name}
        isMulti={isMulti}
        isLoading={queries.some((query) => query.isFetching)}
        isDisabled={isDisabled}
        value={currentValue}
        filterOption={null}
        isSearchable
        showCount={canShowCount}
        options={options}
        noOptionsMessage={
          minCharacterMessage
            ? ({ inputValue: currentValue }) =>
                minCharacter > currentValue.length
                  ? minCharacterMessage
                  : undefined
            : undefined
        }
        classNamePrefix='react-select'
        onFocus={onFocus}
        onMenuClose={() => {
          selectRef.current.blur();
        }}
        onInputChange={(newValue) => {
          setInputValue(newValue);
        }}
        onChange={(selectedOption) => {
          onChange(selectedOption);
        }}
        isOptionDisabled={(option) => option.disabled ?? false}
        data-cy={name}
      />
    </>
  );
}

export default TypeAhead;
