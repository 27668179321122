import * as React from 'react';

export const MarketingFillIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8246 4.16667L15.7018 2.5H17.4561V8.56445C17.9786 8.85301 18.3333 9.38421 18.3333 10C18.3333 10.6158 17.9786 11.147 17.4561 11.4355V17.5H15.7018L14.8246 15.8333L6.92983 13.3333V17.5H5.17544V13.3333H3.42106C2.45615 13.3333 1.66667 12.5833 1.66667 11.6667V8.33333C1.66667 7.41667 2.45615 6.66667 3.42106 6.66667H6.92983L14.8246 4.16667ZM6.92983 8.33333H3.42106V11.6667H6.92983V8.33333Z'
        fill='currentColor'
      />
    </svg>
  ),
);
