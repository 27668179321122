import { Flex, Nav, Navbar } from '@wyz/ui';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { Fragment, useEffect } from 'react';
import { useTheme } from '~/common/context';
import { Logo } from '~/common/components';
// eslint-disable-next-line @wyz/module/no-relative-import
import NavbarVerticalMenu from './navbar-vertical-menu';
// eslint-disable-next-line @wyz/module/no-relative-import
import ToggleButton from './toggle-button';
import routes from '~/common/menu';

const NavbarVertical = () => {
  const {
    navbarStyle,
    isNavbarVerticalCollapsed,
    showBurgerMenu,
    navbarBreakPoint,
  } = useTheme();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time: ReturnType<typeof setTimeout> | null = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    if (!isNil(time)) clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={clsx('navbar-vertical', {
        [`navbar-${navbarStyle as string}`]: navbarStyle !== 'transparent',
      })}
      variant='light'
    >
      <Flex className='fixed-navbar' alignItems='center'>
        <ToggleButton />
        <Logo at='navbar-vertical' width={40} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(/img/bg-navbar.png)`
              : 'none',
        }}
      >
        <div className='navbar-vertical-content scrollbar'>
          <Nav className='flex-column navbar-nav' as='ul'>
            {routes.map((route) => (
              <Fragment key={route.label}>
                {route.children && (
                  <NavbarVerticalMenu routes={route.children} />
                )}
              </Fragment>
            ))}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
