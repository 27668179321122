import * as React from 'react';

export const DuplicateIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <g clipPath='url(#clip0_17992_93944)'>
        <path
          d='M5.83333 5.00002V2.50002C5.83333 2.27901 5.92113 2.06704 6.07741 1.91076C6.23369 1.75448 6.44565 1.66669 6.66667 1.66669H16.6667C16.8877 1.66669 17.0996 1.75448 17.2559 1.91076C17.4122 2.06704 17.5 2.27901 17.5 2.50002V14.1667C17.5 14.3877 17.4122 14.5997 17.2559 14.7559C17.0996 14.9122 16.8877 15 16.6667 15H14.1667V17.5C14.1667 17.96 13.7917 18.3334 13.3275 18.3334H3.33917C3.22927 18.334 3.12033 18.313 3.0186 18.2714C2.91687 18.2298 2.82436 18.1685 2.74638 18.0911C2.6684 18.0137 2.60649 17.9216 2.56421 17.8201C2.52193 17.7187 2.50011 17.6099 2.5 17.5L2.5025 5.83335C2.5025 5.37335 2.8775 5.00002 3.34167 5.00002H5.83333ZM4.16917 6.66669L4.16667 16.6667H12.5V6.66669H4.16917ZM7.5 5.00002H14.1667V13.3334H15.8333V3.33335H7.5V5.00002Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_17992_93944'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
);
