import { FieldOperator, IFilter, IFilterCondition } from '@wyz/types';
import {
  ColumnDefinition,
  DatatableState,
  IFilterForm,
  RowModel,
} from './types';
import { isArrayOfOption, isOption } from '@wyz/utils';
import { get } from 'lodash-es';

export const getRowIdentifier = <TDataItem extends RowModel>(
  column: ColumnDefinition<TDataItem>,
) => {
  return (column.filterable && column.filter.field) || column.field;
};

export const isEqualCondition = <TDataItem extends RowModel>(
  condition: { column?: string },
  column: ColumnDefinition<TDataItem>,
) => {
  return condition.column && condition.column === getRowIdentifier(column);
};

function hasValidCondition(condition: IFilterCondition): unknown {
  return (
    condition.column &&
    ((Array.isArray(condition.value) &&
      condition.value.length > 0 &&
      condition.operator === 'in') ||
      (condition.value && condition.operator === 'eq'))
  );
}

const serializeServerPayload = (
  conditions: DatatableState['criteria']['conditions'],
): Array<Required<IFilterCondition>> => {
  return conditions
    .filter((condition) => hasValidCondition(condition))
    .map((condition) => {
      let value = condition.value;
      if (isArrayOfOption(condition.value)) {
        value = condition.value.map((item) => item.value);
      } else if (isOption(condition.value)) {
        value = condition.value;
      }
      return {
        column: condition.column as string,
        value: value,
        operator: condition.operator as FieldOperator,
      };
    });
};

export const transformCriteria = <TCriteria>(
  rawFilter: IFilter,
  transformer?: (query: IFilter) => TCriteria,
) => {
  const criteria = {
    ...rawFilter,
    conditions: serializeServerPayload(rawFilter.conditions),
  } as IFilter;
  return transformer ? transformer(criteria) : (criteria as TCriteria);
};

export const formToCondition = <TDataItem extends RowModel>(
  columns: Array<ColumnDefinition<TDataItem>>,
  formData?: IFilterForm,
) => {
  const conditions: Array<Required<IFilterCondition>> = [];
  columns.forEach((column) => {
    if (!column.filterable) {
      return;
    }
    const columnField = getRowIdentifier(column);
    conditions.push({
      column: columnField,
      value: get(formData, columnField),
      operator: column.filter.operator,
    });
  });
  return conditions;
};
