import { IOptionItem } from '@wyz/types';
import { isObject } from 'lodash-es';

export function isArrayOfString(arr: unknown): arr is Array<string> {
  return Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'string';
}
export function isArrayOfOption(arr: unknown): arr is Array<IOptionItem> {
  return (
    Array.isArray(arr) &&
    (arr.length === 0 ||
      (arr.length > 0 &&
        isObject(arr[0]) &&
        'value' in arr[0] &&
        'label' in arr[0]))
  );
}
export function isOption(option: unknown): option is IOptionItem {
  return isObject(option) && 'value' in option && 'label' in option;
}

export const isTruthy = <T>(value?: T | null | false): value is T => {
  return !!value;
};

export const isEmptyValue = <T>(value: T): boolean =>
  value === null ||
  value === undefined ||
  value === '' ||
  (Array.isArray(value) && value.length === 0);
