import { notify } from '@wyz/ui';
import { authHandlers, callApi, invariant } from '@wyz/utils';
import React, { useCallback, useMemo } from 'react';
import { useConfig, useI18n } from '~/common/context';
import { log } from '~/common/helpers';
// eslint-disable-next-line @wyz/module/no-relative-import
import { ApiContext } from './contexts';
import { useAuth } from 'react-oidc-context';

export function ApiProvider(props: React.PropsWithChildren) {
  const auth = useAuth();
  const { apiUrl } = useConfig();
  const { t } = useI18n();

  const onExpire = useCallback(() => {
    notify.warning(t('app.common.notification.logout'));
    auth
      .signoutSilent({
        extraQueryParams: {
          ref: location.pathname, // TODO: react-oidc-context is not adding this QS to the login url :(
        },
        silentRequestTimeoutInSeconds: 4,
      })
      .catch((error: Error) => {
        log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(() => {
    invariant(auth.user?.access_token);
    authHandlers().setToken(auth.user?.access_token);
    const baseApi = callApi({
      baseUrl: apiUrl,
      onExpire: onExpire,
    });
    return { call: baseApi };
  }, [auth.user?.access_token, apiUrl, onExpire]);

  return (
    <ApiContext.Provider value={context}>{props.children}</ApiContext.Provider>
  );
}
export const useApi = () => {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiContext');
  }
  return context;
};
